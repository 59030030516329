import React from 'react';
import parse from 'html-react-parser';
import Header from './Header';
import Feed from './Feed';
import Pagination from './Pagination';
import Footer from './Footer';
import InstaFeed from './InstaFeed';

const Blog = props => {
  const { allWpPost, allWpCategory, wp } = props.data;
  const { generalSettings } = wp;
  const { title, description } = generalSettings;

  const postNodes = allWpPost.edges.map(edge => edge.node);

  const categoryNames = allWpCategory.nodes
    .map(node => ({ name: node.name, uri: node.uri }))
    .filter(({ name }) => name !== 'Uncategorized');
 
  return (
    <>
      <Header location="/" title={parse(title)} subtitle={parse(description)} />        
      <main className="container container--wide js-blog-posts grid">        
        <Feed posts={postNodes} />                
      </main>
      <Pagination pageContext={props.pageContext} />
      <InstaFeed />
      <Footer categories={categoryNames} />
    </>
  );
};

export default Blog;
