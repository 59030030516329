import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import Layout from '../components/Layout';
import Blog from '../components/Blog';

const IndexRoute = props => {
  const { data, pageContext } = props;
  const { wp, site, file } = data; 
  const { pageNumber } = pageContext;
  const { title: siteTitle, description: siteDesc, language, url: siteUrl } = wp.generalSettings;

  const featuredImage = {
    node: {
      mediaItemUrl: siteUrl + file.publicURL,
      mediaDetails: {
        width: 1920,
        height: 1080,
      },
    },
  };  
  
  const link = siteUrl + (pageNumber > 0 ? `/Seite/${pageNumber+1}` : '');
  const title = `Blog - ${parse(siteTitle)}`;
  const description = `Ich bin Anne und dies hier ist mein persönlicher Blog. Im Fluss des Lebens zeigt sich hier ein Mensch als x-mal anders fühlend und fragend.`;
  const type = `website`;
  const ogImg = image => image !== undefined ? image : featuredImage.node;

  const jsonLd = {
    "@context": `https://schema.org/`,
    "@type": type,
    url: link,
    headline: title,
    publisher: {
        "@type": `Organization`,
        name: siteTitle,
        logo: {
            "@type": `ImageObject`,
            url: featuredImage.node.mediaItemUrl,
            width: featuredImage.node.mediaDetails.width,
            height: featuredImage.node.mediaDetails.height,
        },
    },
    image: featuredImage.node ? {
      "@type": `ImageObject`,
      url: featuredImage.node.mediaItemUrl,
      width: featuredImage.node.mediaDetails.width,
      height: featuredImage.node.mediaDetails.height,
    } : undefined,
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <html lang={language} />
          <title>{title}</title>
          <link rel="canonical" href={link} />     
          <link rel="pingback" href="https://xmalanderssein.de/xmlrpc" />          
          <meta name="description" content={description} />          
          <meta name="robots" content={site.siteMetadata.robots.join(',')} />
          <meta property="og:site_name" content={siteTitle} />
          <meta property="og:type" content={type} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={link} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:url" content={link} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={ogImg().mediaItemUrl} />
          <meta property="og:image" content={ogImg().mediaItemUrl} />
          <meta property="og:image:width" content={ogImg().mediaDetails.width} />
          <meta property="og:image:height" content={ogImg().mediaDetails.height} />
					<meta name="google-site-verification" content="l5FCVcaO9LO7rocX46rGmx_wtbWEjqUOTQE4DibrMJA" />
          <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
        </Helmet>
        <div />
        <Blog {...props} />
      </div>
    </Layout>
  );
};

export default IndexRoute;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    file(name: {eq: "logo-wp"}) {
      publicURL
    }
    site {
      siteMetadata {
        adminUrl   
        rss
        robots
      }
    }
    wp {
      generalSettings {
        description
        language
        title
        url
      }
    }
    allWpPost(sort: {fields: date, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          title
          date(formatString: "DD.MM.YYYY")
          excerpt
          slug
          seo {
            readingTime
          }
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                    transformOptions: {fit: CONTAIN}
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }          
        }
      }
    }
    allWpPage {
      edges {
        node {
          uri
          title
        }
      }
    }
    allWpCategory {
      nodes {
        name
        uri
      }
    }
  }
`;
